import {
    Container,
    Row,
    Col
} from 'reactstrap';
import styles from './styles.module.scss';
import clsx from "classnames";
import PropTypes from "prop-types";
import Form from "@/components/Shared/Form";
import EditableImage from "@/components/EditableImage";
import EditableText from "@/components/EditableText";

export default function DefaultPageTemplate({
    className,
    global,
    pathPrefix,
    post,
    successPageUrl
}) {
    return (
        <>
            <section className={clsx(className, styles.root)}>
                <Container>
                    {post.content && (
                        <div dangerouslySetInnerHTML={{ __html: post.content }} />
                    )}
                    {post?.form && (
                        <Form
                            className={`mt-5`}
                            formId={post?.form}
                            successPageUrl={successPageUrl}
                        />
                    )}
                </Container>
            </section>
        </>
    )
}

DefaultPageTemplate.defaultProps = {
    pathPrefix: "",
    className: "py-5",
    successPageUrl: "/submission-success"
};

DefaultPageTemplate.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
    successPageUrl: PropTypes.string
};