import React, { Suspense, useState, useEffect, useRef } from "react";
// import { Global, Collection, Jobs } from "@sourceflow-uk/sourceflow-sdk";
import { SourceFlowHead } from "@sourceflow-uk/sourceflow-head";
import metaObject from "@/.sourceflow/metadata.json";
import sourceflowEnums from "@/sourceflow.enums";
import SubBanner from "@/components/SubBanner";
import ImageWithText from "components/ImageWithText";

// import LatestJobs from "components/LatestJobs";
// import ImageWithTextSlanted from "components/FocusConsultant";
// import MultiValues from "components/MultiValues";
// import LatestBlogs from "@/components/LatestBlogs";
// import OtherSpecialisms from "components/OtherSpecialisms";
// import AccordionFAQ from "@/components/AccordionFAQ";
// import DualColumnTimelineCTA from "components/DualColumnTimelineCTA";
// import SimpleContent from "components/Shared/SimpleContent";
// import ReadyCTABanner from "components/ReadyCTABanner";




// const SubBanner = React.lazy(() => import("@/components/SubBanner"));
// const ImageWithText = React.lazy(() => import("components/ImageWithText"));

const ReadyCTABanner = React.lazy(() => import("components/ReadyCTABanner"));
const LatestJobs = React.lazy(() => import("components/LatestJobs"));
const ImageWithTextSlanted = React.lazy(() => import("components/FocusConsultant"));
const MultiValues = React.lazy(() => import("components/MultiValues"));
const LatestBlogs = React.lazy(() => import("@/components/LatestBlogs"));
const OtherSpecialisms = React.lazy(() => import("components/OtherSpecialisms"));
const AccordionFAQ = React.lazy(() => import("@/components/AccordionFAQ"));
const DualColumnTimelineCTA = React.lazy(() => import("components/DualColumnTimelineCTA"));
const SimpleContent = React.lazy(() => import("components/Shared/SimpleContent"));




import { router } from "@/routes";
import { useLocale } from "@/hooks/useLocale";
/**
 *
 * @param global
 * @param meta
 * @returns {JSX.Element}
 * @constructor
 */
export default function DefaultTeachingAndTATemplate({
    global,
    meta,
    page,
    jobs,
    national_college_point_values,
    blogs,
    teachers,
    contact_us_page_faq
}) {
    const locale = useLocale();


    function LazyLoadComponent({ children }) {
        const [isVisible, setIsVisible] = useState(false);
        const ref = useRef();

        useEffect(() => {
            // Ensure the page starts at the top
            window.scrollTo(0, 0);

            const observer = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        setIsVisible(true);
                        observer.disconnect(); // Stop observing once the component is in view
                    }
                },
                { threshold: 0.1 } // Adjust threshold as needed
            );

            if (ref.current) {
                observer.observe(ref.current);
            }

            return () => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            };
        }, []);

        return (
            <div ref={ref}>
                {isVisible ? children : <div className="loader"><div>Loading...</div></div>} {/* Placeholder until in view */}
            </div>
        );
    }

    return (
        <>
            <div className="bg-light-white">
                <SubBanner
                    global={global}
                    // pathPrefix={`teaching_jobs.${page.url_slug}.jobs_by_location.page.sub_banner`}
                    pathPrefix={`teaching_jobs.${page.url_slug === "exam-invigilator-jobs" ? `${page.url_slug}.new` : page.url_slug}.page.sub_banner`}
                    heading={`${page.title}`}
                    content={<p>Tradewind prides itself on hiring exceptional talent for SEN Teaching jobs across the country. </p>}
                    breadcrumb_title={<><span className="active">{page.title}</span></>}
                    wave_color={`light-grey`}
                    // cta_title_1={`View Jobs in ${page.title}`}
                    // cta_link_1={`/`}
                    editable_heading={false}
                />
                <ImageWithText
                    global={global}
                    // pathPrefix={`teaching_jobs.${page.url_slug}.page.image_with_text`}
                    pathPrefix={`teaching_jobs.${page.url_slug === "exam-invigilator-jobs" ? `${page.url_slug}.new` : page.url_slug}.page.image_with_text`}
                    heading={`Our Passion for SEN Recruitment`}
                    lead_content={
                        <>
                            <p>At Tradewind, we are committed to ensuring that we provide the best and brightest educators for our
                                SEN schools</p>
                        </>
                    }
                    content={
                        <>
                            <p>We recognise the vital work that our SEN Teachers perform, as helping children with special educational needs to receive the best possible education is an important and meaningful endeavor. </p>
                            <p><b>We regularly recruit for a selection of SEN Teacher roles, including:</b></p>
                            <ul>
                                <li>SEN Teacher Jobs</li>
                                <li>SEMH Teacher jobs</li>
                                <li>PMLD Teacher jobs</li>
                                <li>Outreach Teacher jobs</li>
                                <li>ASD Teacher jobs</li>
                            </ul>
                        </>
                    }
                    content_html={true}
                    post={``}
                    className="pt-5 style-1 teaching-jobs"
                    imageURL={"/images/placeholders/teaching-jobs-dual-img-placeholder.png"}
                />
                <Suspense fallback={<div className="loader"><div>Loading...</div></div>}>
                    <LazyLoadComponent>
                        <LatestJobs
                            global={global}
                            // pathPrefix={`teaching_jobs.${page.url_slug}.page.latest_jobs`}
                            pathPrefix={`teaching_jobs.${page.url_slug === "exam-invigilator-jobs" ? `${page.url_slug}.new` : page.url_slug}.page.latest_jobs`}
                            heading={`Latest Jobs`}
                            post={jobs}
                            with_swiper={true}
                            heading_tag={`h2`}
                            button_cta_title={`Search Jobs`}
                            button_cta_link={`/jobs`}
                            className={`teaching-jobs`}
                            unixposttime={true}
                        />
                        <ImageWithTextSlanted
                            global={global}
                            // pathPrefix={`teaching_jobs.${page.url_slug}.page.image_with_text_slanted.1`}
                            pathPrefix={`teaching_jobs.${page.url_slug === "exam-invigilator-jobs" ? `${page.url_slug}.new` : page.url_slug}.page.image_with_text_slanted.1`}
                            // heading={`Experience FREE award-winning CPD`}
                            content={
                                <>
                                    <p>Our teams of SEN Specialist Consultants work exclusively to recruit SEN Teachers and support staff for mainstream schools and alternative provisions across the UK for daily supply, long-term and permanent SEN vacancies. Our schools and provisions have pupils with moderate to complex needs including PMLD, SLD, ASD, mental health and emotional issues. </p>
                                </>
                            }
                            // button_cta_title={`Discover CPD opportunities`}
                            // button_cta_link={`/cpd`}
                            imageURL={`/images/placeholders/girl-on-headset-placeholder.png`}
                            className="style-1 teaching-jobs-1 bg-green flip-column"
                            heading_tag={`h3`}
                        // label_text={`Name Surname, Position`}
                        // label_image={`/images/placeholders/tnc-logo.png`}
                        />
                        {/* <MultiValues
                global={global}
                pathPrefix={`teaching_jobs.${page.url_slug}.page.multi_values`}
                post={national_college_point_values}
            /> */}
                        <ImageWithTextSlanted
                            global={global}
                            // pathPrefix={`teaching_jobs.${page.url_slug}.page.image_with_text_slanted.2`}
                            pathPrefix={`teaching_jobs.${page.url_slug === "exam-invigilator-jobs" ? `${page.url_slug}.new` : page.url_slug}.page.image_with_text_slanted.2`}
                            heading={`Supporting your development as well as your SEN Teaching Job search`}
                            content={
                                <>
                                    <p>Whether you are a newly qualified teacher looking for a challenge at an SEN school, or an accomplished SEN Teacher on the lookout for your next opportunity, we are keen to hear from you. If you have a combination of teaching passion, SEN experience, and knowledge of the latest innovative classroom techniques, we encourage you to get in contact with us to join our extensive talent network. </p>
                                </>
                            }
                            button_cta_title={`Get in touch`}
                            button_cta_link={`/contact-us`}
                            imageURL={`/images/placeholders/men-on-headset-placeholder.png`}
                            className="style-1 teaching-jobs-2 bg-blue"
                            heading_tag={`h3`}
                        />
                        <SimpleContent
                            global={global}
                            pathPrefix={`teaching_jobs.${page.url_slug === "exam-invigilator-jobs" ? `${page.url_slug}.new` : page.url_slug}.page.simple_content.1`}
                            // pathPrefix={`teaching_jobs.${page.url_slug}.page.simple_content.1`}
                            lead_content={
                                <>
                                    <p>Our commitment to our candidates extends far beyond merely finding them a new role. By investing in developing their professional skills, we ensure that they are ready to take on any SEN Teacher position. We are proud to offer a selection of CPD courses for our SEN Teachers, including: </p>
                                    <ul>
                                        <li>SEND Communication Strategies</li>
                                        <li>Teaching Phonics Effectively</li>
                                        <li>EAL Learners in the Mainstream Classroom</li>
                                        <li>How to plan a good lesson in accordance with OFSTED</li>
                                        <li>Keeping Children Safe in Education (KCSIE) and Safeguarding</li>
                                        <li>Creating Behaviour for Learning</li>
                                    </ul>
                                </>
                            }
                            content={
                                <>
                                    <p>As well as these comprehensive courses, we offer complimentary TeamTeach training. This program is ideal for SEN Teachers looking to enrich their skills, covering key topics like de-escalation strategies and positive handling techniques for children and young people. Once you have sent us your CV, we’ll identify your core qualities and present you with a list of positions that we think you’d be perfect for.</p>
                                </>
                            }
                            className="teaching-jobs"
                            style1={true}
                        />
                        <DualColumnTimelineCTA
                            global={global}
                            pathPrefix={`teaching_jobs.${page.url_slug === "exam-invigilator-jobs" ? `${page.url_slug}.new` : page.url_slug}.page.dual_column_timeline_cta`}
                            left_heading={`Seeking the Latest ${page.title}?`}
                            left_subtitle={``}
                            left_content={
                                <>
                                    <p>By placing the country’s most outstanding SEN Teachers into key SEN roles, we are playing an active role in ensuring that children with special educational needs receive the learning experiences they deserve. We understand how demanding SEN positions can be, and that’s why we go the extra mile to make sure that our candidates feel supported. Even after we have found you your ideal position, we’ll remain in conta</p>
                                </>
                            }
                            left_cta_link={`${page?.dualcolumntimelinecta_left_column_link_1 ? page?.dualcolumntimelinecta_left_column_link_1 : "/"}`}
                            left_cta_title={`Send CV`}
                            right_heading={`Looking for Inspiring SEN Teachers?`}
                            right_subtitle={``}
                            right_content={
                                <>
                                    <p>If your SEN school is in need of highly skilled SEN Teachers, you have come to the right place. With an extensive network of SEN educators with expertise in de-escalation strategies and positive handling techniques, we can provide you with the professionals you need to help your school to thrive. Reach out to us today to learn about how we can source the best SEN Teacher candidates for your vacancies. </p>
                                </>
                            }
                            right_cta_link={`${page.dualcolumntimelinecta_right_column_link_1 ? page?.dualcolumntimelinecta_right_column_link_1 : "/"}`}
                            right_cta_title={`Find the Best SEN Teacher Candidates`}
                            className="teaching-jobs"
                            cta_class={`primaryBtn`}
                            text_align={`left`}
                            heading={`Connecting Top SEN Teaching Talent With Schools Nationwide`}
                            left_image={`/images/placeholders/men-smilling-placeholder.png`}
                            right_image={`/images/placeholders/men-in-meeting-placeholder.png`}
                        />
                        {page.faq?.length >= 1 && (
                            <AccordionFAQ
                                global={global}
                                pathPrefix={`teaching_jobs.${page.url_slug}.page.accordion_faq`}
                                post={page?.faq}
                                content={`FREQUENTLY ASKED QUESTIONS`}
                                heading={`Mi sodales libero ut tellus venenati`}
                                button_title_cta={`View all FAQs`}
                                button_link_cta={`/`}
                                className={`teaching-jobs py-5`}
                            />
                        )}
                        <OtherSpecialisms
                            global={global}
                            pathPrefix={`teaching_jobs.${page.url_slug === "exam-invigilator-jobs" ? `${page.url_slug}.new` : page.url_slug}.page.other_specialisms`}
                            heading={`Learn More About Our Other Education Recruitment Specialisms `}
                            post={teachers}
                            className="teaching-jobs"
                            content={
                                <>
                                    <ul>
                                        <li><a href="/">Primary Jobs</a></li>
                                        <li><a href="/">Secondary Jobs</a></li>
                                        <li><a href="/">Tertiary Jobs</a></li>
                                    </ul>
                                </>
                            }
                        />
                        <LatestBlogs
                            global={global}
                            pathPrefix={`teaching_jobs.${page.url_slug === "exam-invigilator-jobs" ? `${page.url_slug}.new` : page.url_slug}.page.latest_blogs`}
                            post={blogs}
                            heading={`Latest Insights From Our SEN Teacher Recruitment Team`}
                            content={
                                <p>Our specialist consultants strive to keep our Teachers and schools up to date with the latest trends and developments that are shaping the world of SEN. Check out our latest insights to discover more about hot-button issues like government funding, classroom management techniques, and best practices for behaviour de-escalation.</p>
                            }
                            heading_tag={`h4`}
                            cta_title={`View more Insights`}
                            cta_link={`/blog`}
                            className={`teaching-jobs`}
                            disable_swiper={true}
                            // swiperLoop={false}
                            // xl={`3`}
                            // lg={`3`}
                            // sm={`1`}
                            alignHeading={`start`}
                            notpostpage={true}
                        />
                        <div className="pt-5 bg-light-white"></div>
                        <ReadyCTABanner
                            global={global}
                            pathPrefix={`teaching_jobs.${page.url_slug}.jobs_by_location.page.ready_cta_banner`}
                        />
                    </LazyLoadComponent>
                </Suspense>
            </div>
        </>
    );
}
