import {
    TwitterShareButton,
    TwitterIcon,
} from 'next-share';

export const TwitterShareTemplate = ({ pageUrl, pageTitle }) => {
    return (
        <TwitterShareButton
            url={`${pageUrl}`}
            quote={pageTitle}
            title={`${pageTitle}`}
        >
            <TwitterIcon size={44} round />
        </TwitterShareButton>
    );
}
