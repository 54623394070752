import styles from "@/components/Shared/SF_TestimonialCard_002/styles.module.scss";
import clsx from "classnames";
import PropTypes from "prop-types";
import {
    Card
} from 'reactstrap';
import EditableImage from "@/components/EditableImage";
import Rating from "./svg/rating.svg";
import testimonials from '@/.sourceflow/locations.json';
import job_sectors from '@/.sourceflow/jobs-job_titles.json';

export default function SF_TestimonialCard_002({
    className,
    pageTitle,
    pageContent,
    pageSubtitle,
    pagePosition,
    postImage,
    pageLocation,
    pageSector,
    post
}) {

    //Jobs by Location Tag
    const matchedLocation = testimonials?.category_values?.find(category =>
        category.id === pageLocation
    );
    //Jobs by Job Titles/Sectors
    const matchedJobSectors = job_sectors?.category_values?.find(category =>
        category.id === pageSector
    );

    return (
        <Card className={`${clsx(className, styles.root)} ${post.sector.replace(/\s+/g, '-').toLowerCase()}`}>
            <div>
                <div className="top-header position-relative z-index-1">
                    <EditableImage
                        className={`image`}
                        img={`${postImage ? postImage : "/site-assets/svg/user-placeholder.svg"}`}
                        size={"500x"}
                        alt={``}
                        height={`auto`}
                    />
                    <div className="d-flex flex-wrap gap-2">
                        {/* <span className="label-tag">Primary</span> */}
                        {/* {matchedJobSectors && (
                            <span className="label-tag">{n?.job_titles?.en}</span>
                        )} */}
                        <span className="label-tag">{post.sector}</span>
                        {matchedLocation && (
                            <span className="label-tag location">{matchedLocation?.title?.en}</span>
                        )}
                    </div>
                    <Rating />

                </div>
                <div className='post-title'>{pageTitle}</div>
                {pageContent && (
                    <div className="post-content" dangerouslySetInnerHTML={{ __html: pageContent }} />
                )}
                {pageSubtitle && (
                    <div className='post-subtitle'>{pageSubtitle}</div>
                )}
                {pagePosition && (
                    <div className='post-position'>{pagePosition}</div>
                )}
            </div>
        </Card>
    )
}

SF_TestimonialCard_002.defaultProps = {
    className: "",
};

SF_TestimonialCard_002.propTypes = {
    className: PropTypes.string,
};