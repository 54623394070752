import {
    FacebookShareButton,
    FacebookIcon,
} from 'next-share';

export const FacebookShareTemplate = ({ pageUrl, pageTitle }) => {
    return (
        <FacebookShareButton url={pageUrl} quote={pageTitle} >
            <FacebookIcon size={44} round />
        </FacebookShareButton>
    );
}