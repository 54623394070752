import {
    TelegramShareButton,
    TelegramIcon,
} from 'next-share'

export const TelegramShareTemplate = ({ pageUrl, pageTitle }) => {
    return (
        <TelegramShareButton
            url={`${pageUrl}`}
            title={`${pageTitle}`}
        >
            <TelegramIcon size={44} round />
        </TelegramShareButton>
    );
}
