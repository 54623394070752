import { FacebookShareTemplate } from '@/components/Shared/SF_SocialShareCard_001/templates/FacebookShareTemplate';
import { TwitterShareTemplate } from '@/components/Shared/SF_SocialShareCard_001/templates/TwitterShareTemplate';
import { LinkedinShareTemplate } from '@/components/Shared/SF_SocialShareCard_001/templates/LinkedinShareTemplate';
import { WhatsappShareTemplate } from '@/components/Shared/SF_SocialShareCard_001/templates/WhatsappShareTemplate';
import { TelegramShareTemplate } from '@/components/Shared/SF_SocialShareCard_001/templates/TelegramShareTemplate';

export const shareButtonTemplatesEnum = {
    facebook: (pageTitle, pageUrl) => FacebookShareTemplate({ pageTitle, pageUrl }),
    twitter: (pageTitle, pageUrl) => TwitterShareTemplate({ pageTitle, pageUrl }),
    linkedin: (pageTitle, pageUrl) => LinkedinShareTemplate({ pageTitle, pageUrl }),
    whatsapp: (pageTitle, pageUrl) => WhatsappShareTemplate({ pageTitle, pageUrl }),
    telegram: (pageTitle, pageUrl) => TelegramShareTemplate({ pageTitle, pageUrl })
};
