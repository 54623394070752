import { getShareButtonTemplate } from '@/components/Shared/SF_SocialShareCard_001/functions/getShareButtonTemplate.js';
import styles from "@/components/SF_LatestTestimonials_001/styles.module.scss";
import clsx from "classnames";
import PropTypes from "prop-types";
import {
    Card,
    Container
} from 'reactstrap';

import EditableText from "@/components/EditableText";
import Arrow from '@/public/site-assets/svg/swiper-arrow.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

import { motion } from 'framer-motion';
import React, { useRef, useState, useEffect } from 'react';
import SF_TestimonialCard_002 from "@/components/Shared/SF_TestimonialCard_002";


export default function SF_LatestTestimonials_001({
    className,
    pathPrefix,
    heading,
    heading_tag,
    swiperID,
    subtitle,
    subtitle_tag,

    xl,
    lg,
    md,
    sm,
    post
}) {

    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);


    const settings = {
        modules: [Navigation, Autoplay, Pagination],
        loop: true,
        spaceBetween: 20,
        centeredSlides: false,
        slidesPerView: 1,
        autoHeight: false,
        speed: 500,
        // effect: "fade",
        // fadeEffect: { crossFade: true },
        autoplay: {
            delay: 8000,
        },
        navigation: {
            nextEl: `.${swiperID}.swiper-next`,
            prevEl: `.${swiperID}.swiper-prev`
        },
        pagination: {
            el: `.${swiperID}.swiper-pagination`,
            clickable: true,
            renderBullet: (index, className) => {
                return `<span class="${className}">${index + 1}</span>`;
            },
        },

        breakpoints: {
            320: {
                slidesPerView: sm,
            },
            768: {
                slidesPerView: md,
            },
            991: {
                slidesPerView: lg,
            },
            1399: {
                slidesPerView: xl,
            }
        }
    }

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1 // Adjust threshold as needed, make it smaller for more sensitive detection
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        observer.observe(sectionRef.current);

        // Cleanup function
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const mobileDevice = window.innerWidth <= 991; // Adjust threshold for mobile devices
            // setIsVisible(!mobileDevice); // Hide motion on mobile devices
        };

        handleResize(); // Call initially
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <section ref={sectionRef} className={clsx(className, styles.root)}>
            <Container>
                <div className='mb-5'>
                    <div className='d-lg-flex justify-content-between gap-4 align-items-center'>
                        <div className='d-lg-flex align-items-center gap-5 flex-wrap flex-lg-nowrap'>
                            {heading && (
                                <EditableText
                                    content={heading}
                                    path={`${pathPrefix}.heading`}
                                    global={global}
                                    tag={`${heading_tag}`}
                                    className={`h3 heading`}
                                />
                            )}
                        </div>
                        <div className='d-none d-lg-flex align-items-center'>
                            <button
                                className={`${swiperID} swiper-prev border-0 bg-transparent`}
                                aria-label="Prev"
                            >
                                <Arrow />
                            </button>
                            <button
                                className={`${swiperID} swiper-next border-0 bg-transparent`}
                                aria-label="Next"
                            >
                                <Arrow />
                            </button>
                        </div>
                    </div>
                    {subtitle && (
                        <EditableText
                            content={subtitle}
                            path={`${pathPrefix}.subtitle`}
                            global={global}
                            tag={`${subtitle_tag}`}
                            className={`subtitle`}
                        />
                    )}
                    <div className='swiper-container'>
                        <Swiper {...settings} >
                            {post.map((postItem, index) => (
                                <SwiperSlide key={index}>
                                    <motion.div className='h-100' initial={{ opacity: 0, y: 50 }} animate={isVisible ? { opacity: 1, y: 0 } : {}} transition={{ duration: 0.5, delay: index * 0.1 }}>
                                        <SF_TestimonialCard_002
                                            post={postItem}
                                            pageTitle={`${postItem.title}`}
                                            pageContent={`${postItem.content}`}
                                            pageSubtitle={`${postItem.subtitle}`}
                                            pagePosition={`${postItem.position}`}
                                            postImage={`${postItem.image}`}
                                            pageSector={`${postItem.sector}`}
                                            pageLocation={`${postItem.location}`}
                                        />
                                    </motion.div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className={`${swiperID} swiper-pagination position-relative d-inline-flex d-lg-none justify-content-center`} />

                    </div>
                </div>
            </Container>
        </section>
    )
}

SF_LatestTestimonials_001.defaultProps = {
    className: "py-5",
    xl: 3,
    lg: 3,
    md: 3,
    sm: 2,
};

SF_LatestTestimonials_001.propTypes = {
    className: PropTypes.string,
    xl: PropTypes.number,
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number
};