import {
    Container,
    Row,
    Col
} from 'reactstrap';
import styles from './styles.module.scss';
import EditableText from "@/components/EditableText";
import EditableImage from "@/components/EditableImage";
import clsx from "classnames";
import PropTypes from "prop-types";
import Link from "next/link"
import Button from "@/components/Button";
import Wave from "@/public/site-assets/svg/wave-1.svg"

export default function ReadyCTABanner({
    className,
    pathPrefix,
    heading,
    content,
    content_html,
    imageURL,
    cta_title_1,
    cta_link_1,
    cta_class_1,
    heading_tag
}) {

    return (
        <>
            <section className={clsx(className, styles.root)}>
                <div className='position-relative'>
                    <Container className='position-relative z-index-1'>
                        <div className='wrapper flex-wrap flex-lg-nowrap'>
                            <div className='inner-wrapper d-flex'>
                                <div>
                                    {heading && (
                                        <EditableText
                                            content={heading}
                                            path={`${pathPrefix}.heading`}
                                            global={global}
                                            tag={`${heading_tag}`}
                                            className={`h3 mb-3`}
                                        />
                                    )}
                                    {content && (
                                        <EditableText
                                            content={content}
                                            path={`${pathPrefix}.content`}
                                            global={global}
                                            tag={`div`}
                                            className={`d-none d-lg-block`}
                                            html={content_html}
                                        />
                                    )}
                                    {cta_title_1 && (
                                        <div className='mt-4'>
                                            <Button
                                                text={`${cta_title_1}`}
                                                url={`${cta_link_1}`}
                                                className={`m-0 ${cta_class_1} d-none d-lg-inline-block`}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className='position-relative d-none d-lg-block'>
                                    <EditableImage
                                        className={``}
                                        img={{
                                            path: `${pathPrefix}.image.1`,
                                            src: `${imageURL ? imageURL : "/images/placeholders/get-started-new.png"}`,
                                        }}
                                        size={"x1000"}
                                        alt={``}
                                        height={`100%`}
                                    />
                                </div>
                            </div>
                            <div className='d-block d-lg-none'>
                                {content && (
                                    <EditableText
                                        content={content}
                                        path={`${pathPrefix}.content`}
                                        global={global}
                                        tag={`div`}
                                        className={``}
                                        html={content_html}
                                    />
                                )}
                                {cta_title_1 && (
                                    <div className='mt-4'>
                                        <Button
                                            text={`${cta_title_1}`}
                                            url={`${cta_link_1}`}
                                            className={`m-0 ${cta_class_1} w-100 d-flex justify-content-center`}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Container>
                    {/* <div className='overlay-img'>
                        <Wave />
                    </div> */}
                </div>
            </section >
        </>
    )
}
ReadyCTABanner.defaultProps = {
    pathPrefix: "",
    className: "",
    heading: <>Ready to <span>get started?</span></>,
    content: <p>The next step is to send us your details. If you’re suitable, a consultant will be assigned to help you and will be in touch as soon as possible.</p>,
    content_html: true,
    imageURL: "/images/placeholders/get-started-new.png",
    cta_title_1: "Send Your CV",
    cta_link_1: "/get-started",
    cta_class_1: "secondaryBtn",
    heading_tag: "div"
};

ReadyCTABanner.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    content_html: PropTypes.bool,
    imageURL: PropTypes.string,
    cta_title_1: PropTypes.string,
    cta_link_1: PropTypes.string,
    cta_class_1: PropTypes.string,
    heading_tag: PropTypes.string
};