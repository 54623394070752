import {
    LinkedinShareButton,
    LinkedinIcon,
} from 'next-share';

export const LinkedinShareTemplate = ({ pageUrl, pageTitle }) => {
    return (
        <LinkedinShareButton url={pageUrl} quote={pageTitle} >
            <LinkedinIcon size={44} round />
        </LinkedinShareButton>
    );
}