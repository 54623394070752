import {
    Container,
    Row,
    Col
} from 'reactstrap';
import styles from './styles.module.scss';
import clsx from "classnames";
import PropTypes from "prop-types";
import Form from "@/components/Shared/Form";
import EditableImage from "@/components/EditableImage";
import EditableText from "@/components/EditableText";
import ValidatedValueCard from "@/components/Shared/ValidatedValueCard";

export default function ImageWithText({
    className,
    global,
    pathPrefix,
    heading,
    content,
    imageURL,
    content_html,
    post,
    lead_content
}) {

    return (
        <>
            <section className={clsx(className, styles.root)}>
                <Container>
                    <div className='wrapper'>
                        <div>
                            {heading && (
                                <EditableText
                                    content={heading}
                                    path={`${pathPrefix}.heading`}
                                    global={global}
                                    tag={`h2`}
                                    className={`h3 mb-3`}
                                />
                            )}
                            {imageURL && (
                                <EditableImage
                                    className={``}
                                    img={{
                                        path: `${pathPrefix}.image`,
                                        src: `${imageURL ? imageURL : "/images/placeholders/form-img-placeholder.png"}`,
                                    }}
                                    size={"800x"}
                                    alt={``}
                                    height={`auto`}
                                />
                            )}
                        </div>
                        <div>
                            {lead_content && (
                                <EditableText
                                    content={lead_content}
                                    path={`${pathPrefix}.lead_content`}
                                    global={global}
                                    tag={`div`}
                                    className={`focused-text`}
                                    html={lead_content}
                                />
                            )}
                            {content && (
                                <EditableText
                                    content={content}
                                    path={`${pathPrefix}.content`}
                                    global={global}
                                    tag={`div`}
                                    className={`content`}
                                    html={content_html}
                                />
                            )}
                            {post?.length >= 1 && (
                                <div className='d-flex flex-wrap column-wrapper'>
                                    {post.map((post, index) =>
                                        <div className="column" key={index}>
                                            <ValidatedValueCard post={post} />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

ImageWithText.defaultProps = {
    pathPrefix: "",
    className: "py-5",
    imageURL: "/images/placeholders/form-img-placeholder.png",
    content_html: false

};

ImageWithText.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
    imageURL: PropTypes.string,
    content_html: PropTypes.bool
};