import React from "react";
// import { Global } from "@sourceflow-uk/sourceflow-sdk";
import { SourceFlowHead } from "@sourceflow-uk/sourceflow-head";
import sourceflowEnums from "@/sourceflow.enums";
import ReadyCTABanner from "components/ReadyCTABanner";
import SimpleFormWithColumn from "components/SimpleFormWithColumn";
import SubBanner from "@/components/SubBanner";
import { useRouter } from 'next/router';
import FooterWave from "@/public/site-assets/svg/footer-wave.svg";


import { router } from "@/routes";
import { useLocale } from "@/hooks/useLocale";
/**
 *
 * @param global
 * @param meta
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReferAFriend({
    global,
    meta,
    page
}) {
    const locale = useLocale();
    const router = useRouter();


    // Helper function to capitalize the first letter of each word in a string
    const capitalizeEachWord = (string) => {
        return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };
    // Split the path into breadcrumb parts and exclude the last segment
    const pathParts = router.asPath.split('/').filter(part => part);
    const breadcrumbParts = pathParts.slice(0, -1);

    // Generate breadcrumb elements
    const breadcrumbs = breadcrumbParts.map((part, index) => {
        const href = '/' + breadcrumbParts.slice(0, index + 1).join('/');
        const text = capitalizeEachWord(part.replace(/-/g, ' '));
        return (
            <span key={href}>
                <a href={href}>{text}</a>
            </span>
        );
    });


    return (
        <>
            <div className="bg-light-white">
                <SubBanner
                    global={global}
                    pathPrefix={`${page.url_slug.replace(/-/g, '_')}.page.sub_banner`}
                    heading={`${page.title}`}
                    content={<p>Amet eget a mauris quis dignissim lacus lectus nec. Suspendisse facilisis ipsum fermentum viverra tincidunt viverra felis vulputate amet.</p>}
                    breadcrumb_title={<>{breadcrumbs}<span className="active">{page.title}</span></>}
                    wave_color={`light-grey`}
                />
                <SimpleFormWithColumn
                    global={global}
                    pathPrefix={`${page.url_slug.replace(/-/g, '_')}.page.simple_form_with_column`}
                    heading={`Do you know somebody who would be a good fit?`}
                    content={<p>Neque vestibulum aliquet bibendum porttitor. Cursus adipiscing arcu nunc pulvinar egestas non bibendum non. Vulputate pellentesque et.</p>}
                    formId={`${page?.form}`}
                    salesforce_embed={page.url_slug == "get-started" ? true : false}
                />
                <div className="pt-lg-5"></div>
                {page.url_slug == "get-started" ?
                    <FooterWave /> :
                    <ReadyCTABanner
                        global={global}
                        pathPrefix={`${page.url_slug.replace(/-/g, '_')}.page.ready_cta_banner`}
                    />}
            </div>
        </>
    );
}

// -- Reference -- //
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflow-head
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflowtext
// https://github.com/sourceflow-uk/components/pkgs/npm/job-search