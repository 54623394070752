
import {
    Container,
    Row,
    Col
} from 'reactstrap';
import styles from './styles.module.scss';
import EditableText from "@/components/EditableText";
import clsx from "classnames";
import PropTypes from "prop-types";
import BreadCrumb from "@/components/Shared/BreadCrumb";
import Link from "next/link"
import Wave2 from "/public/site-assets/svg/wave-2.svg"
import WaveChild from "/public/site-assets/svg/waveChild.svg"
import OverlayDots from "/public/site-assets/svg/overlay-dots.svg"
import LightBlulbIcon from "/public/site-assets/svg/light-blub.svg"
import BrushIcon from "/public/site-assets/svg/brush.svg"
import LeafIcon from "/public/site-assets/svg/leaf.svg"
import PotIcon from "/public/site-assets/svg/pot.svg"
import RulerIcon from "/public/site-assets/svg/ruler.svg"
import EditableImage from "@/components/EditableImage";
import { format } from 'date-fns'
import SocialShare from "@/components/Shared/SF_SocialShareCard_001";
import sourceflowEnums from "@/sourceflow.enums";
import Button from "@/components/Button";
import { useRouter } from 'next/router';
import debounce from 'lodash.debounce';

// import { JobSearchStandaloneWidget } from "@sourceflow-uk/job-search";
// import "@sourceflow-uk/job-search/dist/bundle.css";
import JobSearchInput from "@/components/JobSearchInput";


import { motion } from 'framer-motion';
import React, { useRef, useState, useEffect } from 'react';

export default function SubBanner({
    className,
    global,
    pathPrefix,
    content,
    heading,
    content_html,
    breadcrumb_title,
    wave_color,
    blog_style,
    blog_author,
    page_blog_category,
    page_blog_tag,
    post,
    waveIcon,
    cta_title_1,
    cta_link_1,
    cta_class_1,
    showJobSearchInput,
    editable_heading,
    waveChild=false,
    tnc_logo
}) {
    const router = useRouter();
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1 // Adjust threshold as needed, make it smaller for more sensitive detection
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        observer.observe(sectionRef.current);

        // Cleanup function
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const mobileDevice = window.innerWidth <= 991; // Adjust threshold for mobile devices
            // setIsVisible(!mobileDevice); // Hide motion on mobile devices
        };

        handleResize(); // Call initially
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);



    const [headerHeight, setHeaderHeight] = useState(0);
    // useEffect(() => {
    //     const handleResize = () => {
    //         const headerElement = document.getElementById('header');
    //         if (headerElement) {
    //             setHeaderHeight(headerElement.getBoundingClientRect().height);
    //         }
    //     };

    //     // Initial call
    //     handleResize();

    //     // Event listener for window resize
    //     window.addEventListener('resize', handleResize);

    //     // Cleanup function
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);
    const updateHeaderHeight = debounce(() => {
        const headerElement = document.getElementById('header');
        if (headerElement) {
            setHeaderHeight(headerElement.getBoundingClientRect().height);
        }
    }, 200); // Adjust debounce time as needed

    useEffect(() => {
        // Initial call with a slight delay to ensure element is fully rendered
        setTimeout(updateHeaderHeight, 50);

        // Event listener for window resize
        window.addEventListener('resize', updateHeaderHeight);

        // Cleanup function
        return () => {
            window.removeEventListener('resize', updateHeaderHeight);
        };
    }, []);

    return (
        <>
            <section ref={sectionRef} className={`${clsx(className, styles.root)} sub-banner ${blog_style ? "blog-page" : ""}`} style={{ marginTop: `${headerHeight}px` }}>
                {blog_style && (
                    <EditableImage
                        className={`overlay-img position-absolute`}
                        img={`${post?.banner_image ? post.banner_image : "/images/placeholders/blog-img-placeholder.jpeg"}`}
                        size={"500x"}
                        alt={``}
                        height={`100%`}
                    />
                )}
                <Container className='position-relative'>
                    <motion.span className='floating-icons' initial={{ opacity: 0 }} animate={isVisible ? { opacity: 1 } : {}} transition={{ duration: 1.5, delay: 0.3 }}><LightBlulbIcon /></motion.span>
                    <motion.span className='floating-icons' initial={{ opacity: 0 }} animate={isVisible ? { opacity: 1 } : {}} transition={{ duration: 1.5, delay: 2.3 }}><BrushIcon /></motion.span>
                    <motion.span className='floating-icons' initial={{ opacity: 0 }} animate={isVisible ? { opacity: 1 } : {}} transition={{ duration: 1.5, delay: 1.3 }}><LeafIcon /></motion.span>
                    <motion.span className='floating-icons' initial={{ opacity: 0 }} animate={isVisible ? { opacity: 1 } : {}} transition={{ duration: 1.5, delay: 2 }}><PotIcon /></motion.span>
                    <motion.span className='floating-icons' initial={{ opacity: 0 }} animate={isVisible ? { opacity: 1 } : {}} transition={{ duration: 1.5, delay: 1 }}><RulerIcon /></motion.span>

                    <div className={`position-relative wrapper pb-2 ${blog_style || tnc_logo ? "d-flex gap-4 flex-wrap flex-lg-nowrap align-items-center justify-content-between" : ""}`}>
                        <div>
                            <BreadCrumb
                                title={breadcrumb_title}
                                className={`py-2 ${showJobSearchInput == true ? "d-none d-lg-block" : ""}`}
                            />
                            {heading && (
                                <motion.div className={`position-relative ${showJobSearchInput == true ? "text-center text-lg-start" : ""}`} initial={{ opacity: 0, y: 50 }} animate={isVisible ? { opacity: 1, y: 0 } : {}} transition={{ duration: 0.5 }}>
                                    {editable_heading ?
                                        <EditableText
                                            content={heading}
                                            path={`${pathPrefix}.heading`}
                                            global={global}
                                            tag={`h1`}
                                            className={`mb-3 mt-3`}
                                        /> :
                                        <h1 className='mb-3 mt-3'>{heading}</h1>}
                                </motion.div>
                            )}
                            {content && (
                                <motion.div initial={{ opacity: 0, y: 50 }} animate={isVisible ? { opacity: 1, y: 0 } : {}} transition={{ duration: 0.5, delay: 0.3 }}>
                                    <EditableText
                                        content={content}
                                        path={`${pathPrefix}.content`}
                                        global={global}
                                        tag={`div`}
                                        className={`${showJobSearchInput == true ? "d-none d-lg-block" : ""}`}
                                        html={content_html}
                                    />
                                </motion.div>
                            )}
                            {cta_title_1 && (
                                <div className='mt-4'>
                                    <Button
                                        text={
                                            <EditableText
                                                content={cta_title_1}
                                                path={`${pathPrefix}.cta_title_1`}
                                                global={global}
                                                tag={`span`}
                                                className={`${showJobSearchInput == true ? "d-none d-lg-block" : ""}`}
                                                html={false}
                                            />}
                                        url={`${cta_link_1}`}
                                        className={`m-0 ${cta_class_1}`}
                                    // showArrow={false}
                                    />
                                </div>
                            )}
                            <div className='d-flex align-items-center gap-4 flex-wrap position-relative'>
                                {blog_author?.length >= 1 && (
                                    blog_author.map((post, index) => (
                                        <div className={`d-flex gap-2 align-items-center author`} key={index}>
                                            <EditableImage
                                                className={``}
                                                img={`${post?.image ? post.image : "/site-assets/images/img-placeholder.jpg"}`}
                                                size={"200x200"}
                                                alt={`${post.title}`}
                                                height={`100%`}
                                            />
                                            <div>{post.title}</div>
                                        </div>
                                    ))
                                )}
                                {post?.published_date && (
                                    <div className=''>
                                        {format(
                                            new Date(post?.published_date.replace(/-/g, "/")),
                                            "do LLLL y"
                                        )}
                                    </div>
                                )}
                                <div className='d-flex flex-wrap gap-2 align-items-center'>
                                    {page_blog_category?.length >= 1 && (
                                        page_blog_category.map((post, index) => (
                                            <span className={`tag`} key={index}>{post.title}</span>
                                        ))
                                    )}
                                    {page_blog_tag?.length >= 1 && (
                                        page_blog_tag.map((post, index) => (
                                            <span className={`tag ${post.title.toLowerCase().replace(/ /g, '-')}`} key={index}>{post.title}</span>
                                        ))
                                    )}
                                    {/* {post?.tag.length >= 1 && (
                                        post.tag.map((post, index) => (
                                            <span className={`tag`} key={index}>{post}</span>
                                        ))
                                    )} */}
                                </div>
                            </div>
                        </div>
                        {tnc_logo && (
                            <div className='tnc-logo'>
                                <EditableImage
                                    className={`tnc-logo`}
                                    img={{
                                        path: `${pathPrefix}.cpd_logo`,
                                        src: `${tnc_logo}`,
                                    }}
                                    size={"500x"}
                                    alt={``}
                                    height={`auto`}
                                />
                            </div>
                        )}
                        {/* {blog_style && (
                            <div>
                                <SocialShare
                                    pageTitle={`${post.title}`}
                                    pageUrl={`${sourceflowEnums.seo.baseURL}/${router.pathname.includes("/events/") ? "events" : `${router.pathname.includes("/downloads/") ? "downloads" : `${router.pathname.includes("/reviews/") ? "reviews" : "blog"}`}`}/${post.url_slug}`}
                                    blogPage={true}
                                    className={`blog-style social-share z-index-1`}
                                />
                            </div>
                        )} */}
                    </div>
                    {showJobSearchInput && (
                        <JobSearchInput />
                    )}
                    <motion.div className='overlay-dots' initial={{ opacity: 0, y: 50 }} animate={isVisible ? { opacity: 1, y: 0 } : {}} transition={{ duration: 1.5 }}><OverlayDots /></motion.div>
                    <motion.div className='overlay-dots' initial={{ opacity: 0, y: -50 }} animate={isVisible ? { opacity: 1, y: 0 } : {}} transition={{ duration: 1.5 }}><OverlayDots /></motion.div>
                </Container>
                {waveIcon && !waveChild && (
    <div className={`wave ${wave_color}`}>
        <Wave2 />
    </div>
)}
{waveChild && (
    <div className="waveChild">
        <WaveChild />
    </div>
)}

            </section>

        </>
    )
}



SubBanner.defaultProps = {
    pathPrefix: "",
    className: "pt-4",
    editableText: true,
    title: "",
    content: "",
    breadcrumb_title: "",
    content_html: true,
    wave_color: "",
    waveIcon: true,
    cta_title_1: "",
    cta_link_1: "",
    cta_class_1: "primaryBtn",
    showJobSearchInput: false,
    editable_heading: true
};

SubBanner.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
    editableText: PropTypes.bool,
    title: PropTypes.string,
    content: PropTypes.string,
    breadcrumb_title: PropTypes.string,
    content_html: PropTypes.bool,
    wave_color: PropTypes.string,
    waveIcon: PropTypes.bool,
    cta_title_1: PropTypes.string,
    cta_link_1: PropTypes.string,
    cta_class_1: PropTypes.string,
    showJobSearchInput: PropTypes.bool,
    editable_heading: PropTypes.bool,
};
