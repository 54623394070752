import {
    Container,
    Row,
    Col,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem
} from 'reactstrap';
import styles from './styles.module.scss';
import clsx from "classnames";
import PropTypes from "prop-types";
import Form from "@/components/Shared/Form";
import EditableImage from "@/components/EditableImage";
import EditableText from "@/components/EditableText";
import React, { useRef, useState, useEffect } from 'react';
import LightBlulbIcon from "/public/site-assets/svg/light-blub.svg"
import BrushIcon from "/public/site-assets/svg/brush.svg"
import LeafIcon from "/public/site-assets/svg/leaf.svg"
import PotIcon from "/public/site-assets/svg/pot.svg"
import RulerIcon from "/public/site-assets/svg/ruler.svg"
import ClipIcon from "/public/site-assets/svg/clip.svg"
import Bagicon from "/public/site-assets/svg/bag.svg"
import { motion } from 'framer-motion';

export default function AccordionFAQ({
    className,
    global,
    pathPrefix,
    post,
    heading,
    content,

    content_tag
}) {
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const [open, setOpen] = useState('0');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1 // Adjust threshold as needed, make it smaller for more sensitive detection
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        observer.observe(sectionRef.current);

        // Cleanup function
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const mobileDevice = window.innerWidth <= 991; // Adjust threshold for mobile devices
            // setIsVisible(!mobileDevice); // Hide motion on mobile devices
        };

        handleResize(); // Call initially
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <section ref={sectionRef} className={clsx(className, styles.root)}>
                <Container className='position-relative'>
                    <div className='floating-icon'><PotIcon /></div>
                    <div className='floating-icon'><RulerIcon /></div>
                    <div className='floating-icon'><LeafIcon /></div>
                    <div className='floating-icon'><ClipIcon /></div>
                    <div className='floating-icon'><Bagicon /></div>
                    {content && (
                        <EditableText
                            content={content}
                            path={`${pathPrefix}.content`}
                            global={global}
                            tag={`${content_tag}`}
                            className={`mb-3 subtitle`}
                        />
                    )}
                    {heading && (
                        <EditableText
                            content={heading}
                            path={`${pathPrefix}.heading`}
                            global={global}
                            tag={`div`}
                            className={`h3 mb-4`}
                        />
                    )}
                    <div>
                        <Accordion open={open} toggle={toggle}>
                            {post?.map((post, index) => (
                                <motion.div className={`w-100`} initial={{ opacity: 0, y: 50 }} animate={isVisible ? { opacity: 1, y: 0 } : {}} transition={{ duration: 0.5, delay: index * 0.1 }}>
                                    <AccordionItem className='w-100'>
                                        <AccordionHeader targetId={`${index}`} tag={`div`}>{post.title}</AccordionHeader>
                                        <AccordionBody accordionId={`${index}`}>
                                            <div dangerouslySetInnerHTML={{ __html: post.content }} />
                                        </AccordionBody>
                                    </AccordionItem>
                                </motion.div>
                            ))}
                        </Accordion>
                    </div>
                </Container>
            </section>
        </>
    )
}

AccordionFAQ.defaultProps = {
    pathPrefix: "",
    className: "py-5",
    heading: "Tincidunt pellentesque tortor",
    content: "FREQUENTLY ASKED QUESTIONS",
    content_tag: "h4"
};

AccordionFAQ.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
    heading: PropTypes.string,
    content: PropTypes.string,
    content_tag: PropTypes.string
};