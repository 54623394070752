import {
    Container,
    Row,
    Col
} from 'reactstrap';
import styles from './styles.module.scss';
import clsx from "classnames";
import PropTypes from "prop-types";
import Form from "@/components/Shared/Form";
import EditableImage from "@/components/EditableImage";
import EditableText from "@/components/EditableText";
import JobCard from "@/components/Shared/JobCard";
import Link from "next/link"
import Arrow from '@/public/site-assets/svg/swiper-arrow.svg';
import LightBlulbIcon from "/public/site-assets/svg/light-blub.svg"
import StarIcon from "/public/site-assets/svg/star.svg"
import LaptopIcon from "/public/site-assets/svg/laptop.svg"

import { motion } from 'framer-motion';
import React, { useRef, useEffect, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';

export default function LatestJobs({
    className,
    global,
    pathPrefix,
    post,
    heading,
    heading_tag,
    with_swiper,
    button_cta_title,
    button_cta_link,
    button_cta_class,
    swiperID = 'latest-jobs',
    xl,
    lg,
    md,
    sm,
    unixposttime
}) {
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [visiblePosts, setVisiblePosts] = useState(9);

    const loadMore = () => {
        setVisiblePosts(prev => prev + 9);
    };


    const settings = {
        modules: [Navigation, Autoplay],
        loop: post?.length >= 4 ? true : false,
        spaceBetween: 40,
        centeredSlides: false,
        slidesPerView: 1,
        autoHeight: false,
        speed: 500,
        autoplay: {
            delay: 5000,
        },
        navigation: {
            nextEl: `.${swiperID}.swiper-next`,
            prevEl: `.${swiperID}.swiper-prev`
        },
        breakpoints: {
            320: {
                spaceBetween: 10,
                slidesPerView: sm,
            },
            768: {
                spaceBetween: 40,
                slidesPerView: md,
            },
            991: {
                spaceBetween: 40,
                slidesPerView: lg,
            },
            1399: {
                spaceBetween: 40,
                slidesPerView: xl,
            }
        }
    }

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1 // Adjust threshold as needed, make it smaller for more sensitive detection
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        observer.observe(sectionRef.current);

        // Cleanup function
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const mobileDevice = window.innerWidth <= 991; // Adjust threshold for mobile devices
            // setIsVisible(!mobileDevice); // Hide motion on mobile devices
        };

        handleResize(); // Call initially
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <section ref={sectionRef} className={clsx(className, styles.root)}>
                <Container className='position-relative'>
                    <div className='floating-icon'><LightBlulbIcon /></div>
                    <div className='floating-icon d-none d-lg-block'><StarIcon /></div>
                    <div className='floating-icon'><LaptopIcon /></div>
                    <div className='wrapper position-relative'>
                        {with_swiper ?
                            <div className='d-flex justify-content-between gap-4 align-items-center mb-5'>
                                <div>
                                    {heading && (
                                        <EditableText
                                            content={heading}
                                            path={`${pathPrefix}.heading`}
                                            global={global}
                                            tag={`${heading_tag}`}
                                            className={`h3`}
                                        />
                                    )}
                                </div>
                                <div className='d-flex align-items-center'>
                                    <button
                                        className={`${swiperID} swiper-prev border-0 bg-transparent`}
                                        aria-label="Prev"
                                    >
                                        <Arrow />
                                    </button>
                                    <button
                                        className={`${swiperID} swiper-next border-0 bg-transparent`}
                                        aria-label="Next"
                                    >
                                        <Arrow />
                                    </button>
                                </div>
                            </div>
                            :
                            <div>
                                {heading && (
                                    <EditableText
                                        content={heading}
                                        path={`${pathPrefix}.heading`}
                                        global={global}
                                        tag={`${heading_tag}`}
                                        className={`h3 text-center mb-5`}
                                    />
                                )}
                            </div>
                        }
                        {with_swiper ?
                            post?.length >= 1 ?
                                <div className="all-jobs-wrapper with-swiper">
                                    <Swiper {...settings}>
                                        {post?.map((post, index) => (
                                            <SwiperSlide key={index}>
                                                <motion.div className={`h-100 pb-3`} initial={{ opacity: 0, y: 50 }} animate={isVisible ? { opacity: 1, y: 0 } : {}} transition={{ duration: 0.5, delay: index * 0.2 }}>
                                                    <JobCard
                                                        global={global}
                                                        unixposttime={unixposttime}
                                                        post={post} />

                                                </motion.div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div> :
                                <div className="text-center">There's no available jobs at the moment.</div>
                            :
                            <>
                                <div className="all-jobs-wrapper">
                                    {post?.length >= 1 && (
                                        post.slice(0, visiblePosts).map((post, index) => (
                                            <div key={index} className='column'>
                                                <JobCard
                                                    global={global}
                                                    post={post} />
                                            </div>
                                        ))
                                    )}
                                </div>
                                {post?.length > visiblePosts && (
                                    <div className='text-center mt-5 pt-4'>
                                        <button onClick={loadMore} className='primaryBtn'>Load More</button>
                                    </div>
                                )}
                            </>
                        }

                        {button_cta_title && (
                            <div className='mt-5 text-center'>
                                {button_cta_title && (
                                    <div className='text-center mt-5 mb-4'><Link href={`${button_cta_link}`} className={`${button_cta_class}`}>{button_cta_title}</Link></div>
                                )}
                            </div>
                        )}
                    </div>
                </Container>
            </section>
        </>
    )
}

LatestJobs.defaultProps = {
    pathPrefix: "",
    className: "py-5",
    heading_tag: "h3",
    with_swiper: false,
    button_cta_title: "",
    button_cta_link: "",
    button_cta_class: "tertiaryBtn",
    xl: 5,
    lg: 4,
    md: 3,
    sm: 2
};

LatestJobs.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
    heading_tag: PropTypes.string,
    with_swiper: PropTypes.bool,
    button_cta_title: PropTypes.string,
    button_cta_link: PropTypes.string,
    button_cta_class: PropTypes.string,
    xl: PropTypes.number,
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number
};