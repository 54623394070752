import {
    Container,
    Row,
    Col
} from 'reactstrap';
import styles from './styles.module.scss';
import clsx from "classnames";
import PropTypes from "prop-types";
import Form from "@/components/Shared/Form";
import EditableImage from "@/components/EditableImage";
import EditableText from "@/components/EditableText";
import { useRouter } from 'next/router';
// import SocialShare from "@/components/Shared/SF_SocialShareCard_001";
import sourceflowEnums from "@/sourceflow.enums";
import SocialLinks from '@/components/Shared/SocialLinks';

export default function SimpleFormWithColumn({
    className,
    global,
    pathPrefix,
    heading,
    content,
    content_html,
    formId,
    successPageUrl,
    imageURL,
    salesforce_embed,
    page_slug,
    social_share,
    share_heading
}) {
    const router = useRouter();

    // console.log(router);

    // const dynamicSuccessPageUrl = successPageUrl || `${router.asPath}/thank-you`;
    // Determine the dynamic success page URL
    const dynamicSuccessPageUrl = successPageUrl || (() => {
        const currentPath = router.asPath;
        if (currentPath.includes('?')) {
            const [basePath, queryParams] = currentPath.split('?');
            return `${basePath}/thank-you?${queryParams}`;
        } else {
            return `${currentPath}/thank-you`;
        }
    })();

    return (
        <>
            <section className={clsx(className, styles.root)}>
                <Container>
                    <div className='wrapper'>
                        <div>
                            {heading && (
                                <EditableText
                                    content={heading}
                                    path={`${pathPrefix}.heading`}
                                    global={global}
                                    tag={`h2`}
                                    className={`h3 mb-3`}
                                />
                            )}
                            {content && (
                                <EditableText
                                    content={content}
                                    path={`${pathPrefix}.content`}
                                    global={global}
                                    tag={`div`}
                                    className={`content`}
                                    html={content_html}
                                />
                            )}
                            {imageURL && (
                                <EditableImage
                                    className={`d-none d-lg-block`}
                                    img={{
                                        path: `${pathPrefix}.image`,
                                        src: `${imageURL ? imageURL : "/images/placeholders/form-img-placeholder.png"}`,
                                    }}
                                    size={"500x"}
                                    alt={``}
                                    height={`auto`}
                                />
                            )}
                        </div>
                        {formId && (
                            salesforce_embed ?
                                <div className='w-100'>
                                    <Form
                                        formId={formId}
                                        successPageUrl={dynamicSuccessPageUrl}
                                        salesforce_embed={true}
                                    />
                                </div> :
                                <div className='w-100'>
                                    <Form
                                        formId={formId}
                                        successPageUrl={dynamicSuccessPageUrl}
                                    />
                                    {social_share && (
                                        // <SocialShare
                                        //     pageTitle={share_heading}
                                        //     pageUrl={`${sourceflowEnums.seo.baseURL}/${page_slug}`}
                                        //     blogPage={true}
                                        //     className={`blog-style social-share mt-4 justify-content-center`}
                                        //     heading_title={"You can also reach us on social media"}
                                        // />
                                        <SocialLinks
                                            className={`pb-0 pt-5 blog-style`}
                                            share_heading={`You can also reach us on social media`}
                                            pathPrefix={`${pathPrefix}.social_share`}
                                            global={global}
                                        />
                                    )}
                                </div>
                        )}
                    </div>
                </Container>
            </section >
        </>
    )
}

SimpleFormWithColumn.defaultProps = {
    pathPrefix: "",
    className: "py-5",
    content_html: true,
    formId: "",
    imageURL: "/images/placeholders/form-img-placeholder.png",
    salesforce_embed: false,
    social_share: false

};

SimpleFormWithColumn.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
    successPageUrl: PropTypes.string,
    content_html: PropTypes.bool,
    formId: PropTypes.string,
    imageURL: PropTypes.string,
    salesforce_embed: PropTypes.bool,
    social_share: PropTypes.bool
};