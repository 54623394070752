import {
    WhatsappShareButton,
    WhatsappIcon,
} from 'next-share';

export const WhatsappShareTemplate = ({ pageUrl, pageTitle }) => {
    return (
        <WhatsappShareButton
            url={`${pageUrl}`}
            title={`${pageTitle}`}
            separator=":: "
        >
            <WhatsappIcon size={44} round />
        </WhatsappShareButton>
    );
}
