
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[...url_slug]",
      function () {
        return require("private-next-pages/[...url_slug].jsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[...url_slug]"])
      });
    }
  