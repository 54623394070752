import { getShareButtonTemplate } from '@/components/Shared/SF_SocialShareCard_001/functions/getShareButtonTemplate.js';
import styles from "@/components/Shared/SF_SocialShareCard_001/styles.module.scss";
import clsx from "classnames";
import PropTypes from "prop-types";
import {
    Card
} from 'reactstrap';

export default function SocialShare({
    className,
    heading_title,
    pageTitle,
    pageUrl,
    blogPage,
    shareButtons = [
        'facebook',
        'twitter',
        'linkedin',
        'telegram',
        'whatsapp']
}) {
    return (
        <Card className={clsx(className, styles.root)}>
            {blogPage ?
                <>
                    <div>{heading_title}</div>
                    <div className='d-flex flex-wrap flex-lg-nowrap justify-content-lg-between inner-wrapper'>
                        {shareButtons.map((templateName, idx) => <span key={idx}>{getShareButtonTemplate(templateName)(pageTitle, pageUrl)}</span>)}
                    </div>
                </>
                :
                <>
                    <div>{heading_title}</div>
                    <div className='px-5 py-3 d-flex justify-content-lg-between inner-wrapper'>
                        {shareButtons.map((templateName, idx) => <span key={idx}>{getShareButtonTemplate(templateName)(pageTitle, pageUrl)}</span>)}
                    </div>
                </>}

        </Card>
    )
}

SocialShare.defaultProps = {
    className: "box mb-4",
    blogPage: false,
    heading_title: "Share"
};

SocialShare.propTypes = {
    className: PropTypes.string,
    blogPage: PropTypes.bool,
    heading_title: PropTypes.string
};