export const router = {
    home: {
        en: `/`,
    },
    jobs: {
        en: `/jobs`,
    },
    "jobs.item": {
        en: `/jobs/[url_slug]`,
    },
    "jobs.internal": {
        en: `/jobs/internal`,
    },
    thank_you_for_applying: {
        en: `/jobs/thank-you-for-applying`,
    },
    pages: {
        en: `/[url_slug]`,
    },
    "schools.refer_a_teacher": {
        en: `/schools/refer-a-teacher`,
    },
    "schools.refer_a_teacher.thank_you": {
        en: `/schools/refer-a-teacher/thank-you`,
    },
    "schools.refer_a_school": {
        en: `/schools/refer-a-school`,
    },
    "schools.refer_a_school.thank_you": {
        en: `/schools/refer-a-school/thank-you`,
    },
    "schools.upload_vacancy": {
        en: `/schools/upload-vacancy`,
    },
    "schools.upload_vacancy.thank_you": {
        en: `/schools/upload-vacancy/thank-you`,
    },
    "schools.our_services": {
        en: `/schools/our-services`,
    },
    "schools.our_services.thank_you": {
        en: `/schools/our-services/thank-you`,
    },
    "schools.the_national_college": {
        en: `/schools/the-national-college`,
    },
    "schools.the_national_college.thank_you": {
        en: `/schools/the-national-college/thank-you`,
    },
    "resources": {
        en: `/resources`,
    },
    "resources.tags": {
        en: `/resources/tags/[url_slug]`,
    },
    "blog": {
        en: `/blog`,
    },
    "blog.item": {
        en: `/blog/[url_slug]`,
    },
    "blog.tags": {
        en: `/blog/tags/[url_slug]`,
    },
    "events": {
        en: `/events`,
    },
    "events.item": {
        en: `/events/[url_slug]`,
    },
    "events.tags": {
        en: `/events/tags/[url_slug]`,
    },
    "downloads": {
        en: `/downloads`,
    },
    "downloads.item": {
        en: `/downloads/[url_slug]`,
    },
    "downloads.tags": {
        en: `/downloads/tags/[url_slug]`,
    },
    "reviews": {
        en: `/reviews`,
    },
    "reviews.item": {
        en: `/reviews/[url_slug]`,
    },
    "reviews.tags": {
        en: `/reviews/tags/[url_slug]`,
    },
    "about.tradewind_careers": {
        en: `/about-us/tradewind-careers`,
    },
    "about.our_charity_party": {
        en: `/about-us/charity-partner`,
    },
    "about.about_tradewind": {
        en: `/about-us/about-tradewind`,
    },
    "cpd": {
        en: `/cpd`,
    },
    "cpd.the_national_college": {
        en: `/cpd/the-national-college`,
    },
    "cpd.the_national_college.thank_you": {
        en: `/cpd/the-national-college/thank-you`,
    },
    "cpd.register_your_interest": {
        en: `/cpd/register-your-interest`,
    },
    "cpd.register_your_interest.thank_you": {
        en: `/cpd/register-your-interest/thank-you`,
    },
    get_started: {
        en: `/get-started`,
    },
    "get_started.thank_you": {
        en: `/get-started/thank-you`,
    },
    "pages.item": {
        en: `/[url_slug]`,
    },
    "contact_us": {
        en: `/contact-us`,
    },
    "contact_us.thank_you": {
        en: `/contact-us/thank-you`,
    },
    "contact_us.item": {
        en: `/contact-us/[url_slug]`,
    },
    "contact_us.item.thank_you": {
        en: `/contact-us/[url_slug]/thank-you`,
    },
    jobs_by_location: {
        en: `/jobs-by-location/[url_slug]`,
    },
    "refer_a_friend": {
        en: `/refer-a-friend`,
    },
    "refer_a_friend.thank_you": {
        en: `/refer-a-friend/thank-you`,
    },
    "submission_success": {
        en: `/submission-success`,
    },
    "the_impact_academy": {
        en: `/the-impact-academy`,
    },
    "sitemap": {
        en: `/site-map`,
    },
};