import {
    Container,
    Row,
    Col
} from 'reactstrap';
import styles from './styles.module.scss';
import clsx from "classnames";
import PropTypes from "prop-types";
import EditableImage from "@/components/EditableImage";
import EditableText from "@/components/EditableText";
import Link from "next/link"
import StarIcon from "/public/site-assets/svg/star.svg"
import OverlayDots from "/public/site-assets/svg/overlay-dots.svg"

import { motion } from 'framer-motion';
import React, { useRef, useState, useEffect } from 'react';

export default function FocusConsultant({
    className,
    global,
    pathPrefix,
    heading,
    content,
    button_cta_link,
    button_cta_title,
    imageURL,
    heading_tag,
    earn_style,
    earn_image_url,
    corner_subtitle,
    label_text,
    label_image,
    wrapper_align
}) {

    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1 // Adjust threshold as needed, make it smaller for more sensitive detection
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        observer.observe(sectionRef.current);

        // Cleanup function
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const mobileDevice = window.innerWidth <= 991; // Adjust threshold for mobile devices
            // setIsVisible(!mobileDevice); // Hide motion on mobile devices
        };

        handleResize(); // Call initially
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <>
            <section ref={sectionRef} className={clsx(className, styles.root)}>
                <Container>
                    <div className='position-relative'>
                        {earn_style != true && (
                            <>
                                <span className='floating-icons'><StarIcon /></span>
                                <span className='floating-icons'><StarIcon /></span>
                                <span className='floating-icons'><StarIcon /></span>
                            </>
                        )}
                        {earn_style && (
                            <>
                                <EditableImage
                                    className={`position-absolute z-index-1 secondary-img d-none d-lg-block`}
                                    img={{
                                        path: `${pathPrefix}.corner.image.1`,
                                        src: `${earn_image_url ? earn_image_url : "/images/placeholders/earn.webp"}`,
                                    }}
                                    size={"800x"}
                                    alt={``}
                                    height={`auto`}
                                />
                                <EditableText
                                    content={corner_subtitle}
                                    path={`${pathPrefix}.corner_subtitle`}
                                    global={global}
                                    tag={`div`}
                                    className={`position-absolute corner-subtitle-text d-none d-lg-block`}
                                />
                            </>
                        )}
                        <div className={`wrapper align-items-${wrapper_align}`}>
                            <div className="position-relative left">
                                {earn_style && (
                                    <>
                                        <EditableImage
                                            className={`position-absolute z-index-1 secondary-img d-block d-lg-none`}
                                            img={{
                                                path: `${pathPrefix}.corner.image.1`,
                                                src: `${earn_image_url ? earn_image_url : "/images/placeholders/earn.webp"}`,
                                            }}
                                            size={"500x"}
                                            alt={``}
                                            height={`auto`}
                                        />
                                        <EditableText
                                            content={corner_subtitle}
                                            path={`${pathPrefix}.corner_subtitle`}
                                            global={global}
                                            tag={`div`}
                                            className={`position-absolute corner-subtitle-text d-block d-lg-none`}
                                        />
                                    </>
                                )}
                                {imageURL && (
                                    <div>
                                        <EditableImage
                                            className={`position-relative z-index-1 featured-img`}
                                            img={{
                                                path: `${pathPrefix}.image`,
                                                src: `${imageURL ? imageURL : "/images/placeholders/form-img-placeholder.png"}`,
                                            }}
                                            size={"500x"}
                                            alt={``}
                                            height={`auto`}
                                        />
                                        {label_image && (
                                            <EditableImage
                                                className={`position-absolute label-img position-relative z-index-1`}
                                                img={{
                                                    path: `${pathPrefix}.label_image`,
                                                    src: `${label_image}`,
                                                }}
                                                size={"500x"}
                                                alt={``}
                                                height={`auto`}
                                            />
                                        )}
                                        {label_text && (
                                            <EditableText
                                                content={label_text}
                                                path={`${pathPrefix}.label_text`}
                                                global={global}
                                                tag={`div`}
                                                className={`label-text position-relative z-index-1 d-none d-lg-inline-block`}
                                            />
                                        )}
                                    </div>
                                )}
                                <div className='overlay-dots position-absolute'><OverlayDots /></div>
                            </div>
                            <div className='position-relative z-index-1'>
                                {heading && (
                                    <EditableText
                                        content={heading}
                                        path={`${pathPrefix}.heading`}
                                        global={global}
                                        tag={`${heading_tag}`}
                                        className={`h3`}
                                    />
                                )}
                                {content && (
                                    <EditableText
                                        content={content}
                                        path={`${pathPrefix}.content`}
                                        global={global}
                                        tag={`div`}
                                        className={`content py-4`}
                                        html={true}
                                    />
                                )}
                                {button_cta_title && (
                                    <Link href={`${button_cta_link}`} className={`secondaryBtn m-0`}>{button_cta_title}</Link>
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

FocusConsultant.defaultProps = {
    pathPrefix: "",
    className: "",
    heading_tag: "h3",
    wrapper_align: "center"

};

FocusConsultant.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
    heading_tag: PropTypes.string,
    wrapper_align: PropTypes.string
}