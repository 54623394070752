import {
    Container,
    Row,
    Col
} from 'reactstrap';
import styles from './styles.module.scss';
import clsx from "classnames";
import PropTypes from "prop-types";

export default function BreadCrumb({
    className,
    title,
}) {
    return (
        <div className={clsx(className, styles.root)}>
            <div className='d-flex align-items-center wrapper flex-wrap'>
                <span><a href="/" target="_self">Home</a></span>
                {title}
            </div>
        </div>
    )
}



BreadCrumb.defaultProps = {
    pathPrefix: "",
    className: "py-2",
};

BreadCrumb.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
};